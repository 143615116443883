import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

export default function Redirect() {
	useEffect(() => {
        // Redirect to a different URL after component mounts
        navigate('/'); // Replace with your desired path
    }, []);

    return (
        <div>
            <h1>Redirecting...</h1>
            <p>If you are not redirected automatically, follow <a href="/">this link</a>.</p>
        </div>
    );
}